@import '../../styles/shared';

$max-widthNavbar: 1216px;

.fsNavbar {
    width: 100%;
    padding: 0 20px;
    &__container {
        max-width: $max-widthNavbar;
        margin: auto;
        position: relative;

        @include displayFlex;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: $default--padding 0;
        

        @include desktop {
            flex-direction: row;
            min-height: 80px;
        }
        &__branding {
            @include displayFlex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            @include desktop {
                width: unset;
            }
        }
    }

    &__menuBtn {
        display: block;
        @include appearance(none);
        border: none;
        background: transparent;
        padding: 8px 10px;
        min-height: 44px;
        @include desktop {
            display: none;
        }
    }

    &__navLinks {
        display: none;
        min-width: 100%;
        
        &--show {
            display: block;
            position: aboslute;
            background: white;
            @include box-shadow($default--box-shadow);
            .fsNavbar__navLinks__menu {
                li {
                    border-top: 1px solid $color-border;
                    a {
                        @include displayFlex;
                        align-items: center;
                        justify-content: space-between;
                    }
                    ul {
                        display: block;
                        position: relative;
                        @include box-shadow(unset);
                        li {
                            a {
                                padding-left: $default--padding+10;
                            }
                        }
                    }
                }
            }
        }
        @include desktop {
            display: block;
            min-width: unset;
            background: unset;
        }
        &__menu {
            @include displayFlex;
            flex-direction: column;
            list-style: none;
            margin: unset;
            padding: unset;

            @include desktop {
                flex-direction: row;
            }

            li {
                // border: 1px dotted orange;
                position: relative;

                &.disabled {
                    a {
                        opacity: 0.5;
                        &:hover {
                            cursor: not-allowed;
                        }
                    }
                }
                a {
                    display: block;
                    color: $color-text;
                    padding: 14px;
                    @include desktop {
                        color: white;
                    }
                    &:hover {
                        + ul {
                            display: block;
                        }
                    }

                    img {
                        margin-left: 8px;
                    }
                }
                &:hover {
                    ul {
                        display: block;
                    }
                }
                ul {
                    display: none;
                    position: absolute;
                    list-style: none;
                    margin: unset;
                    padding: unset;
                    right: 0;
                    min-width: 190px;
                    background: white;
                    @include box-shadow($default--box-shadow);
                    li {
                        a {
                            color: $color-text;
                            &:hover {
                                background: $color-primary--light;
                            }
                            &.current {
                                font-weight: $default--weight-bolder;
                            }
                        }
                        + li {
                            border-top: 1px solid $color-border;
                        }
                    }
                }
            }
        }
    }
}