@import '../../styles/shared';

.exclusion-wrapper{
  @include displayFlex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  @include desktop {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    .nw-button-option {
      max-width: 49%;
    }
  }
  // .nw-button-option{
  //   + .nw-button-option {
  //     margin-top: $default--margin/1.5;
  //     &--clicked{
  //        margin-top: 0px;
  //     }
  //   }
  //   &--clicked{
  //     margin-bottom: 0px;
  //     margin-top: 0px;
  //   }
  // }
  .nw-button-option {
    margin-bottom: $default--margin/1.5;
      // &--clicked{
      // margin-bottom: 0px;
      // }
  }
  
}