@import "../../styles/shared";
@import "~animatewithsass/animate.scss";

$modal--overlayBg: $color-overlay;
$modal--mobileMinWidth: 90vw;
$modal--desktopMinWidth: 740px;
$modal--bg: $white;
$modal--padding: 30px; //$basedPadding;
$modal--minContentHeight: 200px;
$modal-zIndex: 11;

$width-container: 400px;

.app {
  &--desktop {
    .modal-overlay {
      .modal {
        // @include box-shadow(4px -2px 16px 0px  rgba($black, 0.225));
        @include border-radius($default--border-radius);
        overflow: hidden;
        position: relative;
        &--big {
          @include desktop {
            max-height: 548px;
          }
        }
      }
    }
  }
}

.modal-overlay {
  background: $modal--overlayBg;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;

  @include displayFlex;
  // align-items: flex-end;
  justify-content: center;
  z-index: $modal-zIndex;

  @include tablet {
    // background: transparent;
    padding: 30px 0px;
  }

  &__container {
    width: 100%;
    overflow: hidden !important;
    @include border-radius($default--border-radius $default--border-radius 0 0);

    @include tablet {
      width: $width-container;
    }
    @include desktop {
      width: $modal--desktopMinWidth;
    }
    .modal {
      @include displayFlex;
      flex-direction: column;
      // margin: auto;
      background: $modal--bg;
      width: 100%;
      height: 100%;
      // height: 95%;
      @include border-radius($default--border-radius $default--border-radius 0 0);
      @include slideInUp();

      @include tablet {
        width: $width-container;
        max-height: 100%;
        // margin: auto;
        // @include fadeIn();
      }
      @include desktop {
        width: $modal--desktopMinWidth;
        max-height: 500px;
      }

      &__header {
        @include displayFlex;
        justify-content: space-between;
        align-items: center;
        padding: 0 $default--padding/2;
        min-height: $default--navBarSize;
        $iconSize: 24px;
        border-bottom: 1px solid $color-border;

        img,
        span {
          width: $iconSize;
          height: $iconSize;
        }

        &__title {
          margin: unset;
          font-size: $default--h5;
        }
      }

      &__content {
        flex-grow: 1;
        padding: $default--padding;
        overflow: auto;
      }

      &__footer {
        @include displayFlex;
        align-items: center;
        padding: $default--padding $default--padding $default--padding * 1.5 $default--padding;

        @include tablet {
          padding: $default--padding;
          justify-content: flex-end;
        }

        button {
          min-width: 100%;
          @include tablet {
            min-width: 100px;
          }
        }
      }
    }
  }
}
