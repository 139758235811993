@import './font';

// Text Sizes
$h1: $default--h1; // 40px
$h2: $default--h2; // 34px
$h3: $default--h3; // 24px
$h4: $default--h4; // 20px
$h5: $default--h5; // 17px
$h6: $default--h6; // 15px

$bodyTextSize: $default--p !default; // 15px
$smallTextSize: $default--small !default; // 13px

body {
    font-family: $font-body;
    font-size: $bodyTextSize;
    font-weight: $default--weight-normal;
    line-height: 1.325;
    position: relative;
    color: $color-text;
}

p {
    font-size: $bodyTextSize;
}

h1, h2, h3, h4 {
    line-height: 125%;
}

h1 {
    font-size: $h1;
    font-weight: $default--weight-bolder;
}

h2 {
    font-size: $h2;
    font-weight: $default--weight-bolder;
}

h3 {
    font-size: $h3;
    font-weight: $default--weight-bolder;
}

h4 {
    font-size: $h4;
    font-weight: $default--weight-bold;
}

h5 {
    font-size: $h5;
    font-weight: $default--weight-bold;
    line-height: 100%;
    line-height: 140%;
}

h6 {
    font-size: $h6;
    font-weight: $default--weight-bold;
    line-height: 100%;
    margin-bottom: unset;
}

small {
    font-size: $smallTextSize;
}

strong {
    font-weight: bold;
}
