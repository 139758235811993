@import '../../styles/shared';

.app--mobileBrowser {
  .nw-checkmark-option {
    min-height: 86px;
  }  
}

.nw-checkmark-option {
  background: $color-primary;
  width: 100%;
  min-height: 96px;
  @include border-radius($default--border-radius--mid);
  position: relative;
  @include box-sizing(border-box);
  color: $white;
  @include displayFlex;
  align-items: center;
  justify-content: space-between;
  padding: $default--padding/2 $default--padding;
  // padding-left: 38px;

  @include desktop {
    min-height: 86px;
  }

  &__wrapper {
    // border: 1px dotted blue;
    // position: absolute;
    // right: 38px;
    // bottom: 38px;
  }
  &--uncheck {
    background: $color-primary--light;
    color: $color-text;
    .nw-checkmark-option__content {
      &__header {
        min-height: 15px;
      }
    }
  }
  &__content {
    @include displayFlex;
    flex-direction: column;
    justify-content: flex-end;
    margin-right: $default--margin/2;
    min-height: 45px;
    // border: 1px dotted red;

    &__header {
      // height: 20px;
      font-size: ($default--p)-1;
      font-weight: bold;
      @include displayFlex;
      align-items: center;
    }
    &__footer {
      margin-top: 5px;
      transition: height 0.2s ease-out, opacity 0s ease-out;
      opacity: 1;
      height: 20px;
      &--hidden {
        display: block;
        line-height: 0;
        height: 10px;
        overflow: hidden;
        opacity: 0;
      }
    }
  }
  .nw-checkmark {
    border-color: $option-ring;
    &--checked {
      border-color: $white;
    }
  }
  .nw-checkmark__input:checked + .nw-checkmark__container {
    .nw-checkmark__switcher {
      background: $white;
    }
  }
}
