@import '../../styles/shared';

$layout--padding: unset;
$layout--height: 100%;

.layout {
    &--simple {
        @include displayFlex;
        flex-direction: column;

        .layout__body {
            padding: $layout--padding;
            min-height: $layout--height;
            overflow-y: auto;
            overflow-x: hidden;
        }
    }
    
}