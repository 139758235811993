@import '../../../styles/shared';

.icon-btn {
    @include displayFlex;
    position: relative;
    cursor: pointer;
    z-index: 1;
  
    &__content {
      $size: 320px;
      width: $size;

      @include desktop {
        width: $size + 50;
      }

      position: absolute;
      z-index: 100;
      
      // height: 108px; 
      border-radius: $default--border-radius--small;
      padding: $default--padding - 4px;
      // box-shadow: 0px 8px 16px -4px $grey4;
      @include box-shadow(0 4px 12px rgba(35,35,35,0.175));
      right: calc(100% - 20px);
      margin-top: 28px;
      background-color: $white;
      color: $color-text;
      // text-align: center;
      font-weight: lighter;
      font-size: $default--small;
    }
  }
  
