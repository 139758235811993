@import "styles/shared";

.app {
  // height: 100vh;

  // @include desktop {
  //   height: 100%;
  // }

  height: 100%;

  &--mobileBrowser {
    height: 100%;
  }

  &--desktop {
    .app-wrapper {
      /** ADD STYLE ON THE BACKGROUND FOR DESKTOP */
      background: rgb(255, 255, 255);
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(224, 224, 224, 1) 90%,
        rgba(234, 234, 234, 1) 90%,
        rgba(255, 255, 255, 1) 100%
      ) !important;

      @include desktop {
        background: url("./assets/images/ESG-bg-desktop.png") !important;
        background-size: cover !important;
        background-position: center !important;
        background-repeat: no-repeat !important;
      }

      &__container {
        overflow: hidden;
        position: relative;

        @include tablet {
          border: 1px solid $color-border;
          @include box-shadow(4px -2px 16px 0px rgba($black, 0.225));
          @include border-radius($default--border-radius);
        }

        @include desktop {
          border: unset;
          // @include box-shadow(unset);
          @include border-radius(unset);
        }
      }
    }
  }

  .app-wrapper {
    .fsNavbar {
      // width: 100%;
      position: fixed;
      top: 0;
      z-index: 10;
    }
  }
}

.mainIndex {
  .app {
    &--desktop {
      .app-wrapper {
        &__container {
          background: transparent;
          @include box-shadow(unset);
        }
      }
    }
  }
}
