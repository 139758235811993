@import '../../styles/shared';

.esg-input-with-button {
    // border: 1px dotted red;
    &__container {
        @include displayFlex;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: $default--margin/2;

        @include border-radius($default--border-radius--small);
        overflow: hidden;

        input {
            min-height: $default--inputSize;
            min-width: 200px;
            margin: unset;
            flex-grow: 1;
            border: unset;
            padding-left: $default--padding;
            &:focus {
                outline: none;
            }

            @include desktop {
                font-size: $default--h4;
            }
        }

        button {
            min-height: $default--inputSize;
            margin: unset;
            @include border-radius(unset);
            @include displayFlex;
            align-items: center;
            font-size: $default--small;
            min-width: 132px;

            span {
                margin-right: 5px;
            }

            @include desktop {
                font-size: $default--h6;
            }
        }
    }
}