/** Defaults */
$default--box-shadow: 0 8px 16px -8px rgba($black, 0.18) !default;
$default--padding: 20px !default;
$default--margin: 20px !default;

$default--border-radius: 24px !default;
$default--border-radius--mid: 16px !default;
$default--border-radius--small: 8px !default;

$default--labelOpacity: 0.7 !default;

/** Default typography vars */
$default--h1: 40px;
$default--h2: 34px;
$default--h3: 24px;
$default--h4: 20px;
$default--h5: 17px;
$default--h6: 15px;
$default--p: 15px;
$default--small: 13px;
$default--smaller: 11px;

$default--weight-normal: 400;
$default--weight-bold: 500;
$default--weight-bolder: 600;
$default--weight-boldest: 800;

$default--navBarSize: 54px !default;
// $default--navBarSize--small: 48px !default;

$default--inputSize: 54px !default;
$default--inputBaseSize: 48px !default;


$standardPadding: 16px;
$standardMargin: 16px;

// @TODO: REMOVE BELOW ONCE FINAL
// Containers
// $navBarSize: 54px;
// $navBarDashboardSize: 64px;
// $footerBarSize: 52px;
// $desktopHeight: 94vh;

// Buttons
$buttonBaseSize: 48px;
$buttonSmallSize: 32px;


// Radius
$baseRadius: 16px !default;
// $responseRadius: 24px !default;

$minWidthTablet: 600px;
$maxWidthTablet: 768px;
$minWidthDesktop: 980px;

//Based padding
$basedPadding: 20px;
