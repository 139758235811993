@import '../../styles/shared';

$card--bg: $white;
$card--padding: $default--padding;
$card--margin: $default--margin;

.esg-card {
    width: 100%;
    background: $card--bg;
    padding: $card--padding;
    @include box-shadow($default--box-shadow);
    @include border-radius($default--border-radius);

    + .esg-card {
        margin-top: $card--margin;
    }
}