@import '../../styles/shared/shared';

$navbar--defaultHeight: $default--navBarSize;
$gauge--height: 1px;
$gauge--bg: $grey1;

.progress-bar {
    width: 100%;
    // min-height: $navbar--defaultHeight;
    @include displayFlex;
    align-items: flex-start;
    background: transparent;
    position: relative;

    &__container {
        width: 100%;
        height: $gauge--height;
        background: $gauge--bg;
        @include displayFlex;

        &__gauge {
            background-color: $color-secondary;
            height: $gauge--height;
        }
    }
}