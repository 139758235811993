@import '../../styles/shared';

$max-width-tablet: 400px;
$app-height-tablet: 90vh;

.page-loader {
    position: fixed;
    @include displayFlex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;

    @include tablet {
        width: $max-width-tablet;
        max-height: $app-height-tablet;
    }
}