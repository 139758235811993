@import '../../../styles/shared';

.otp {
    height: 100%;
    width: 100%;
    
    @include displayFlex;
    position: relative;

    &__box {
        @include border-radius($default--border-radius);
        @include box-shadow($default--box-shadow);
        @include displayFlex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: $default--padding*1.5 $default--padding;
        margin: $default--margin*1.5 $default--margin;
        background-color: $white;
        // min-width: calc(100% - $default--margin);
        flex-grow: 1;

        @include tablet {
          min-width: 500px;
          flex-grow: 0;
            margin: $default--margin auto;
        }
        
        @include desktop {
            padding: $default--padding*2;
        }

        h5 {
            text-align: center;
            color: $color-text;
            margin-top: 0;
            font-weight: $default--weight-normal;

            b {
                color: $black;
                line-height: 2;
            }
        }

        &__input {
            @include displayFlex;
            justify-content: space-around;
            position: relative;
            margin-bottom: $default--margin*2;
            width: 250px;        
            &__box {
              border: 1px solid $color-border;
              @include border-radius(100%);
              @include displayFlex;
              align-items: center;
              justify-content: center;
            //   font-size: 28px;
              color: $black;
              width: $default--inputSize;
              height: $default--inputSize;
        
              &--active {
                border-color: $color-primary;
              }
            }
        
            input {
              position: absolute;
              border: none;
              width: 89px;
              top: 0px;
              bottom: 0px;
              font-size: 28px;
              text-align: left;
              background-color: transparent;
              &:focus {
                outline: none;
                @include appearance(none);
              }
            }
        }

        &__resend-code {
          @include displayFlex; 

          p {
            color: $color-text;
            margin: 0 $default--margin/3;
            cursor: pointer;
          }

          b {
            color: $color-secondary;
          }

          &--active {
            flex-direction: column;
            align-items: center;

            b {
              margin-top: $default--margin/2;
              color: $grey3;
            }
          }
        }
    }

    .notification {
      margin-bottom: $default--margin;
    }
}