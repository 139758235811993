@import '../../styles/shared';

$chips--spacing: 8px;
$chips--primaryColor: $color-primary--light;
$chips--secondaryColor: $color-secondary--light;
$chips--fontSize: $default--small;

.esg-chips {
    &__title {
        text-align: center;
        font-size: $chips--fontSize;
        margin-top: $default--margin;
        margin-bottom: $default--margin/2;
        font-weight: $default--weight-normal;
        opacity: 0.7;
    }

    &__list {
        list-style: none;
        margin: unset;
        padding: unset;
        @include displayFlex;
        justify-content: center;
        flex-wrap: wrap;
        
        li {
            padding: $chips--spacing;
            @include border-radius($default--border-radius--small/2);
            margin-bottom: $chips--spacing;
            background: $chips--secondaryColor;
            font-size: $chips--fontSize;
            color: $color-text--heading;

            + li {
                margin-left: $chips--spacing;
            }
        }

        &--primary {
            li {
                background: $chips--primaryColor;
            }
        }
    }
}