// @use 'sass:map';
@import '../../styles/shared';

$layout--padding: $default--padding;
$layout--height: 100vh !default;

.layout {
    @include displayFlex;
    flex-direction: column;
    // background-color: map.get($light-colors, 'primary');
    
    &__body {
        padding: $layout--padding;
        @include calc(height, '#{$layout--height} - #{$default--navBarSize}');
        // @include calc(max-height, '#{$layout--height} - #{$default--navBarSize}');
        overflow-y: auto;

        @include tablet {
            $layout--height: 100%;
        }

        @include desktop {
            padding: $layout--padding*1.5 $layout--padding*2;
        }
    }
    &__body::-webkit-scrollbar {
        display: none;
    }
}

// .sponsorv2 {
//     display: none;

//     @include desktop {
//         color: white;
//         position: fixed;
//         right: 32px;
//         bottom: 32px;
//         padding-bottom: unset;
//         border: unset;
//         margin: unset;
//         @include displayFlex;
//         flex-direction: column;
//         span:first-child {
//             font-weight: normal;
//             margin-bottom: 6px;
//         }
//     }

//     &__logo {
//         background: white;
//         padding: 10px 10px 5px 10px;
//         margin-left: 10px;
//         @include border-radius(3px);
//         @include desktop {
//             padding: 10px 10px 8px 10px;
//             margin-left: 0px;
//         }
//     }
// }

/* Hide scrollbar for Chrome, Safari and Opera */
// .layout::-webkit-scrollbar {
//     display: none !important;
// }

// /* Hide scrollbar for IE, Edge and Firefox */
// .layout {
//   -ms-overflow-style: none;  /* IE and Edge */
//   scrollbar-width: none;  /* Firefox */
// }