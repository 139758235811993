@import '../../styles/shared/shared';

$min-height-container:48px;
$min-height-button:42px;
$min-width:120px;

.tabs {
  @include displayFlex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;

  &--2ndStyle {
    .tabs__menu {
      @include border-radius(unset);
      padding: unset;
      margin: unset;
      min-height: unset;
      justify-content: flex-start;

      li {
        flex-grow: unset;
        span {
          background: $color-gradient--grey;
          min-height: $buttonSmallSize;
          font-size: $default--small;
          padding-right: $default--padding/3;
          padding-left: $default--padding/3;
        }
        + li {
          margin-left: 6px;
        }
        &.active {
          span {
            background: $color-primary;
          }
        }
      }
    }
    .tabs__content {
      margin: $default--margin/2 0 0 0;
    }
  }

  &__menu{
    width: 100%;
    @include displayFlex;
    justify-content: center;
    align-items: center;
    min-height:$min-height-container;
    margin: 0;
    padding: 0 3px;
    background-color: $white;
    @include border-radius(10px);

    li{
      list-style: none;
      flex-grow: 1;
      &.active{
        span{
          color: $white;
          background-color: $color-primary;
        }
      }
      span{
        @include border-radius(7px);
        min-height:$min-height-button;
        // min-width:$min-width;
        @include displayFlex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }
  }
  &__content{
    width: 100%;
    margin: 24px 0;
    position: relative;
    // width: 0px;
    // transition: width ease-out 0.3s, opacity 0s ease-out;

    &--normal{
      // transform: translate(500px,0px);
      position: relative;
      left: 700px;
    }

    &--inactive{
      display: none;
      // position: absolute;
      // display: none;
      // transform: translateX(300px);
     // transition-timing-function: ease-out;

      /* A litttttle slower on the way in */
     // transition: 0.25s;
      
      /* Move into place */
      // transform: translate(500px,0px);
    }
    &--active{
      // position: absolute;
      // display: initial;
     // transition-timing-function: ease-out;

      /* A litttttle slower on the way in */
    // transition: 0.25s;
      
      /* Move into place */
      // transform: translate(0px,0px);
      animation-name: slide;
      animation-duration: 0.5s;
      // animation-delay: 1s;
      animation-fill-mode: forwards;
    }
  }
}

@keyframes slide {
  from {left: 0px;} //700
  to {left:0px}
}
