@import '../../styles/shared';

$switch--textColor: $white;

.language-switcher {
    @include displayFlex;
    justify-content: space-between;
    position: relative;
    padding: $default--padding/2 0;
    margin: 0 $default--margin $default--margin*2 $default--margin;
    border-bottom: 1px solid rgba($white, 0.3);
    opacity: 0.8;
    position: relative;

    select {
        z-index: 2;
        @include appearance(none);
        border: none;
        background: transparent;
        color: $switch--textColor;
        padding-right: 30px;
        text-transform: uppercase;

        &:focus {
            outline: none;
        }
    }

    &::before {
        content: '';
        $size: 20px;
        width: $size;
        height: $size;
        position: absolute;
        z-index: 1;
        right: 0;
        background-image: url('../../assets/images/icon-dropdown.svg');
        background-repeat: no-repeat;
        background-position: center;
    }
}