@import '../../styles/shared';

.app {
    &--mobileBrowser {
        .nw-checkmark-image {
            height: 145px; 
        }
    }
}
.nw-checkmark-image{
    background-color: $darkest;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 144px;
    border-radius: 16px;
    position:relative;
    @include border-radius($default--border-radius--small);
    @include box-sizing(border-box);
    color: $white;
    overflow: hidden;
    // align-items: center;
    @include displayFlex;
    padding: $default--padding;
    
    @include desktop {
        height: 140px;
    }

    &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        background: linear-gradient(transparent, $black);
        z-index: 1;
    }

    &-wrapper{
        position:absolute;
        right: 26px;
        top: 26px;
        z-index: 11;
    }
    // &--uncheck{
    //     background:white;
    //     color: black; 
    // }
    &__content{
        @include displayFlex;
        flex-direction: column;
        font-size:15px;
        justify-content: flex-end;
        z-index: 10;
        // height: 45px;
        &--selected {
            justify-content: center;
        }
        &__header{
        //    height:58px;
        //    width:160px;
            width: 98%;
        //    font-weight: bold;
           margin: unset;
        //    font-size: 24px;
        }
        &__footer{
            margin-top: 8px;
            transition: height 0.2s ease-out,opacity 0s ease-out;
            opacity: 1;
            height: 36px;
            &--hidden {
                display: block;
                line-height:0;
                height: 0;
                margin-top: 0;
                overflow: hidden;
                opacity: 0;
                
            }
        }
    }
    .nw-checkmark{
        border-color: white;
        &--checked{
            border-color: $color-primary;
        }
    }
    .nw-checkmark__input:checked + .nw-checkmark__container{
        .nw-checkmark__switcher{
            background:$color-primary;
        }
    }
}