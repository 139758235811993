@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;800&display=swap');

/** Font Variables */
$helvetica: -apple-system, BlinkMacSystemFont, 'Helvetica Neue', sans-serif;
$interFont: 'Inter', $helvetica, sans-serif;

// Primary Font
$font-body: $interFont !default;
$font-heading: $interFont !default;

