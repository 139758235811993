@import '../../styles/shared/shared';

.options{
  @include displayFlex;  
  flex-direction: column;
   justify-content: center;
   align-items: center;
   .nw-checkmark-option {
     + .nw-checkmark-option{
        margin-top: $default--margin/1.5;
     }
   }
 }

 