$bright-green: #27ca47;
$thick-orange: #ff9100;
$white: #ffffff;
$grey: #e5e5e5;
$black: black;
$darkest: #000A0F;
$greenyellow: greenyellow;
$light-orange: #ffe9cc;
$white-smoke: #f5f5f5;
$whisper: #e9e9e9;
$grey1: #F5F5F5;
$grey2: #E9E9E9;
$grey3: #C1C1C1;
$grey4: #4A5154;
$grey5: #707070;
$grey6: #353535;
$grey7: #F3F3F3;
$msBlue: #2B6B9B;

$imas-skyBlue:      #3C90F4;
$imas-lightestBlue: #F1F7FE;
$imas-darkBlue:     #304268;
$imas-daskest:      #000A0F;
$imas-red:          #E14923;
$imas-lighterOrange:  #FFF6F0;
$imas-green:        #2EC23D;

// Global
$color-body: $white !default;
$color-dirtyBody: #f6f6f6;

$color-text: $imas-darkBlue !default;
$color-text--invert: $white !default;
$color-text--heading: $imas-daskest !default;
$color-text--title: $grey4;
$color-text--smallText: $grey5;
$color-text--tag: $grey6;

$color-border: $grey2 !default;

// Primary Color
$color-primary: $imas-skyBlue !default;
.color-primary { color: $color-primary; }
$color-primary--light: $imas-lightestBlue !default;
// $color-primary--light: lighten($color-primary, 46%) !default;
// $color-primary-hover: darken($color-primary, 10);

// Secondary Color
$color-secondary: $imas-red !default;
$color-secondary--light: lighten($color-secondary, 44%) !default;

// Tertiary Color
$color-tertiary: $imas-darkBlue !default;
$color-tertiary--light: lighten($color-tertiary, 44%) !default;

$back-ground-color: $white !default;
$option-ring: $imas-darkBlue !default;
$option-button-bg: $imas-lightestBlue !default;

/** Modal colors */
$color-overlay: rgba($darkest, 0.50) !default;

/** Notification colors */
$color-error: red !default;
$color-success: $bright-green !default;

/** Light colors for primary & secondary */
$light-colors: (
    "primary": lighten($color-primary, 60%),
    "secondary": lighten($color-secondary, 60%),
);

// @debug map.get($light-colors, 'primary');

/** tabs color */
$color-gradient--grey: linear-gradient($grey1, $grey2);