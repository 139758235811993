@import '../../styles/shared';

.esg-slider-wrapper {
    background: $color-primary--light;
    padding: $default--padding;
    margin: $default--margin/2 0;
    @include border-radius($baseRadius);
    
    .esg-slider {
        position: relative;
        &:nth-child(1) { z-index: 4; }
        &:nth-child(2) { z-index: 3; }
        &:nth-child(3) { z-index: 2; }
    }
}

.preferences {
    .filter-slider-wrapper {
        position: relative;
        z-index: 1;
    }
}