.filter-container{
  position: relative;
  margin-top: -10px;
  // overflow: hidden;

  &__items{
    max-height: 1000px;
    transition: max-height ease-out 0.3s, opacity 0s ease-out;
    &--hide{
      max-height: 23px;
      opacity: 0;
    }
  }

  &__ask{
    position: absolute;
    left: 0px;
    // top: -1px;
    font-size: 13px;
    margin-top: 2px;
    margin-bottom: 12px;
    font-weight: 400;
    opacity: 0.7;
    
  }
  &__hide-btn{
    position: absolute;
    right: 0px;
    // top: -6.5px;
    font-size: 13px;
    cursor: pointer;
    padding: 2px 5px;
    z-index: 10;
  }
}

