@import '../../../styles/shared';

.esg-info{
  background: $white;
  // @include border-radius($default--border-radius $default--border-radius 0 0);
  // padding: $default--padding*2 $default--padding;
  // padding: 0 $default--padding $default--padding*2 $default--padding;
  padding-top: $default--padding;
  @include displayFlex;
  flex-direction: column;
  // align-items: center;
  // min-height: 64vh;
  // &__element {
  //   margin-top: unset;
  //   margin-bottom: 16px;
  //   width: 100%;
  //   text-align: center;
  // }
  &__desc {
    // font-size: 15px;
    // text-align: center;
    margin-bottom: 10px;
    // padding: 0 $default--padding;

    h5, h6 {
      margin: unset;
      font-weight: $default--weight-bolder;
    }
  }

  .esg-chips {
    &__title {
      text-align: left;
      margin-top: unset;
    }
    &__list {
      justify-content: unset;
      margin-bottom: $default--margin;
    }
  }
  // &__title {
  //   font-size: 13px;
  //   text-align: center;
  // }
  // &__item{
  //   font-size: 13px;
  //   padding: 6px 8px;
  // }
  &__spacer {
    flex-grow: 1;
  }
  .nw-button{
    //  margin-top: $default--margin+10;
  }
}


.esg-preferences {
  list-style: none;
  padding: 0;
  border-top: 1px solid $color-border;
  &__item {
    @include displayFlex;
    border-bottom: 1px solid $color-border;
    padding: $default--padding/1.5 0;

    &__stat {
      margin-right: $default--margin/2;
      min-width: 110px;
      strong, small {
        display: block;
      }
      strong {
        font-weight: $default--weight-bolder;
      }
      small {
        color: $color-primary;
        margin-top: $default--margin/4;
      }
    }
    article {
      flex-grow: 1;
    }
  }
}