@import '../../../styles/shared';
@import "~animatewithsass/animate.scss";

$searchFilter--overlayBg: $color-overlay;
// $default--border-radius--small: $default--border-radius -1px;

$max-width-tablet: 396px;
$max-icon-size: 18px;
$max-icon-size--small: 12px;
$max-element-size: 24px;

$searchFilter-zIndex: 11;

// .app {
//     &--desktop {
//       .searchFilter-overlay {
//         .searchFilter {
//           @include border-radius($default--border-radius);
//           overflow: hidden;
//           position: relative;
//         }
//       }
//     }
// }

.searchFilter-overlay {
    // background: $searchFilter--overlayBg;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
  
    @include displayFlex;
    justify-content: center;
    z-index: $searchFilter-zIndex;
  
    @include tablet {
      padding: 32px 0px;
    }
    
    &__container {
        background: $searchFilter--overlayBg;
        width: 100%;
        overflow: hidden !important;
        margin-bottom: -1px;
        @include border-radius($default--border-radius $default--border-radius 0 0);

        @include tablet {
            width: $max-width-tablet;
            @include border-radius($default--border-radius);
        }

        &__search{
            background-color: $color-body;
            @include displayFlex;
            flex-direction: column;
            width: 100%;
            @include border-radius($default--border-radius $default--border-radius 0 0);
            @include slideInRight();
        
            @include tablet {
                width: $max-width-tablet;
            }
    
            &__header {
                @include border-radius($default--border-radius $default--border-radius 0 0);
                height: $default--navBarSize;
                @include displayFlex;
                align-items: center;
                padding: $default--padding*1.4 $default--padding $default--padding*0.6;
                border-bottom: 1px solid $back-ground-color;
    
                &__searchIcon {
                    width: $max-icon-size;
                    height: $max-icon-size;
                    margin-right: $default--margin/4;
                }
                input {
                    width: 100%;
                    border: unset;
                    outline: unset;
                    font-size: $default--p;
                }
                &__closeIcon {
                    width: $max-icon-size--small;
                    height: $max-icon-size--small;
                    margin-left: $default--margin/4;
                    cursor: pointer;
                }
            }
            &__section {
                padding: $default--padding;
                @include displayFlex;
                flex-direction: column;
    
                &__header {
                    @include displayFlex;
                    align-items: center;
                    justify-content: space-between;
                    font-size: $default--small;
                    margin-bottom: $default--margin/2;
                    text-transform: uppercase;
                    small {
                        color: $color-text--smallText;
                        opacity: $default--labelOpacity;
                    }
                    .nw-button {
                        // padding: unset;
                        padding: auto;
                        height: auto;
                        color: $color-primary;
                        text-transform: uppercase;
                    }
                }
                &__body {
                    @include displayFlex;
                    flex-flow: wrap;
                    margin-right: -$default--padding/2;
                    .nw-tag {
                        @include displayFlex;
                        align-items: center;
                        @include border-radius($default--border-radius--small/2);
                        margin-right: $default--padding/2;
                        margin-top: $default--padding/2;
                        background-color: $color-primary--light;
                        line-height: unset;
                        height: $max-element-size;
                        padding: $default--padding/4;
                        font-size: $default--small;
                        &__name {
                            margin-right: $default--margin/2;
                        }
                    }
                }
            }
        }
    }
}