@import '../../styles/shared/shared';

.donutChart {
  margin: 0 auto;
  @include border-radius(50%);
  display: block;
  @include box-shadow(4px 7px 14px rgba(35,35,35,0.175));
  &__track{
    fill: transparent;
    stroke: #F1F7FE;
    stroke-width: 26;
  }
  &__indicator{
    stroke-width: 26;
    stroke-dasharray: 0 10000;
    transition: stroke-dasharray 1.5s ease;
    stroke-linecap: round;
  }
  &__text{
    fill: $color-text; // #607580;
    @include displayFlex;
    // display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    &__val{
      font-size: 22px;
      font-weight: $default--weight-bold;
      fill: $color-text--heading;
      @include desktop {
        font-size: 32px;
        line-height: 100%;
      }
    }
    &__label{
      font-size:15px;
    }
  }
 
}
