@import "../../styles/shared";

.filter-slider-wrapper {
  padding-top: 6px;
  position: relative;
  z-index: 10;

  &__header {
    margin-bottom: 8px;
    @include displayFlex;
    justify-content: space-between;
    align-items: center;
    color: $color-text;
    font-size: $default--h6; // $default--small;
    color: $color-text;

    &__words {
      @include displayFlex;
      flex-wrap: wrap;
      // opacity: 0.7;
      span {
        margin-left: 3px;
        @include displayFlex;
        flex-grow: 1;
        font-weight: $default--weight-bolder;
      }
    }

    &__icons {
      @include displayFlex;
      align-items: center;
    }
  }

  &__body {
    padding-bottom: 36px;

    $color-primary: #b0e551;

    .rc-slider-dot {
      display: none;
    }
    .rc-slider-track {
      background-color: $color-primary;
      margin-top: 7px;
      height: 1px;
    }
    .rc-slider-rail {
      background-color: $grey3;
      margin-top: 7px;
      height: 1px;
    }
    .rc-slider-handle {
      $size: 24px;
      background-color: $color-primary;
      width: $size;
      height: $size;
      border: none;
    }

    .rc-slider-mark {
      @include displayFlex;
      justify-content: space-between;
      .rc-slider-mark-text {
        position: unset;
        transform: none !important;
        margin-top: 10px;
        font-size: $default--small;
      }
    }
  }
  &--disabled {
    .filter-slider-wrapper__body {
      opacity: 0.5;
    }
  }

  &--hidden {
    .filter-slider-wrapper__header {
      display: none;
    }
    .filter-slider-wrapper__body {
      display: none;
    }
  }

  + .filter-slider-wrapper {
    .filter-slider-wrapper__header {
      padding-top: 20px;
    }
  }
}

.rc-slider-disabled {
  background-color: inherit;
}
