@import '../../styles/shared';

$btn-height: 44px;
$btn-width: 80px;
$btn-default-radius: $btn-height;

.swipe-buttons {
    display: none;
    
    @include desktop {
        width: 100%;
        position: absolute;
        top: 46%;
        @include displayFlex;
        justify-content: space-between;
        padding: $basedPadding/2 $basedPadding;
    }

    button {
        @include appearance(none);
        @include border-radius($btn-default-radius);
        @include box-shadow($default--box-shadow);
        min-height: $btn-height;
        min-width: $btn-width;
        padding: 12px $basedPadding;
        border: unset;
        color: white;
    }

    &__no {
        background: $imas-red;
    }
    &__yes {
        background: $imas-green;
    }
}