@import '../../styles/shared/shared';
.nw-button-option {
  background: $option-button-bg;
  width: 100%;
  min-height: 64px;
  opacity: 1;
  // transition: min-height ease-out 0.3s, opacity 0s ease-out;
  overflow: auto;
  overflow-y: hidden;
  @include border-radius($baseRadius/2);
  position: relative;
  box-sizing: border-box;
  align-items: center;
  @include displayFlex;
  padding-left: 24px;
  padding-right: 24px;
  color: $color-text;

  justify-content: space-between;

  &--onWhiteBg {
    &.nw-button-option--uncheck {
      background-color: $grey1;
    }
  }

  &-wrapper {
    position: absolute;
    right: 38px;
    bottom: 38px;
  }
  &--uncheck {
    // background: $color-primary--light;
    background: $option-button-bg;
    .nw-button {
      &:hover {
        background: inherit;
      }
      &--primary {
        border-color: $color-primary;
        color: $color-primary;
        // &:hover {
        //   background-color: lighten($color-primary, 6%);
        // }
        &.nw-button--outline {
          &:hover {
            background-color: lighten($color-primary, 6%);
            border-color: lighten($color-primary, 6%);
            color: white;
          }
          &:focus {
            border-color: $color-primary;
            color: $color-primary;
          }
          &:active {
            background-color: transparent;
            border-color: $color-primary;
            
          }
        }
      }
      &--secondary {
        @include border-radius($baseRadius/4);
      }
      // &--outline {
      //   &:hover {
      //     color: white;
      //     // transition: background-color 0.5s ease;
      //   }
      // }
    }
  }

  &--clicked {
    // min-height: 0px;
    // height: 0px;
    // opacity: 0.5;
    transition: background-color 0.5s ease;
    background-color: $color-primary--light;

    &.nw-button-option--secondary {
      background-color: $color-secondary--light;
      button {
        @include border-radius($baseRadius/4);
      }
    }

    button {
      transition: background-color 0.5s ease, width 0.5s ease-in-out;
      // background-color: $color-secondary;
    }
  }
  &__content {
    @include displayFlex;
    flex-direction: column;
    // font-size: 15px;
    justify-content: flex-end;
    &__header {
      height: 20px;
      font-weight: bold;
    }
  }
  .nw-checkmark {
    border-color: $option-ring;
    &--checked {
      border-color: $white;
    }
  }
  .nw-checkmark__input:checked + .nw-checkmark__container {
    .nw-checkmark__switcher {
      background: $white;
    }
  }
}
