@import '../../styles/shared';
@import "~animatewithsass/animate.scss";

$max-width-tablet: 400px;
$app-height-tablet: 90vh;

.streaming-news {
    margin: -$default--padding;

    &__resultError {
        position: fixed;
        @include displayFlex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;

        @include tablet {
            width: $max-width-tablet;
            max-height: $app-height-tablet;
        }
    }
    &__resultNumber {
        margin-top: $default--margin/2;
        text-align: center;
        
        font-size: $default--small;
        color: $grey5;
        font-style: italic;
    }
}
