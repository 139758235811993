@import '../../styles/shared';

.esg-details {
    @include displayFlex;
    justify-content: space-between;
    padding-bottom: $default--padding;
    border-bottom: 1px solid $color-border;

    &__item {
        // border: 1px dotted red;
        text-align: center;
        width: 600px / 300px * 100%;

        &__value {
            margin: 8px 0px;
            font-weight: $default--weight-bold;
        }
        &__label {
            opacity: $default--labelOpacity;
            span {
                display: block;
                opacity: $default--labelOpacity;
            }
        }

        + .esg-details__item {
            margin-left: 6px;
        }
    }
}