@import "../../styles/shared/index";

.switcher {
    position: relative;
    display: inline-block;
    $sliderSize: $buttonSmallSize - 4px;
    width: $sliderSize*2;
    height: $buttonSmallSize;
    margin-right: 10px;

    &__input {
        width: 0;
        height: 0;
        visibility: hidden;
    }

    &__slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: $buttonSmallSize/2;
        background-color: $grey3;
        -webkit-transition: .4s;
        transition: .4s;

        &::before {
            position: absolute;
            content: "";
            height: $sliderSize;
            width: $sliderSize;
            left: 2px;
            bottom: 2px;
            border-radius: $buttonSmallSize;
            background-color: white;
            -webkit-transition: 0.3s;
            transition: 0.3s;
        }
    }

    input:checked + .switcher__slider {
        background-color: $color-primary;
    }

    input:focus + .switcher__slider {
        box-shadow: 0 0 1px $color-primary;
    }

    input:checked + .switcher__slider::before {
        -webkit-transform: translateX(24px);
        -ms-transform: translateX(24px);
        transform: translateX(24px);
    }

    &--disabled {
        .switcher {
            opacity: 0.5;
        }
    }

    &--Hidden {
        .switcher {
            visibility: hidden;
        }
    }

}