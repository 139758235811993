@import "../../styles/shared/shared";
$tag-height: 32px !default;
$color-tag-outline: $thick-orange;

.nw-tag {
   background-color: $white;
   @include border-radius($default--border-radius--small);
   border: none;
   padding: $default--padding/2;
   height: $tag-height;
   line-height: 96%;
   display: inline-block;

   &__normalTagName {
      color: $color-text;
      margin-right: 12px;
      cursor: pointer;
   }

   &__name {
      color: $color-text;
      margin-right: 12px;
   }

   &__x {
      color: $thick-orange;
      font-size: 15px;
   }

   img {
      cursor: pointer;
   }
}
