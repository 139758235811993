$width-container: 400px;
$width-container--desktop: 800px;

.app-wrapper {
    height: 100%;
    background-color: $white;
    padding: 0px;

    @include tablet {
        padding: 30px 0px;
    }

    @include desktop {
        padding: unset;
        position: relative;
    }

    &__container {
        width: 100%;
        height: 100%;
        background-color: $back-ground-color;

        @include tablet {
            width: $width-container;
            max-height: 100%;
            margin: auto;
        }
        
        @include desktop {
            width: $width-container--desktop;
        }
    }
    &__logo {
        display: none;
        @include desktop {
            display: block;
            position: fixed;
            $position: 14px;
            top: $position;
            left: $position + 5;
        }
    }
}
