html, body, #root {
    height: 100%;
}

body {
    // Disable text selection
    -webkit-user-select: none;

    // Disable highlighting
    -webkit-tap-highlight-color: transparent;

    // Disable callouts
    -webkit-touch-callout: none;
}

a {
    text-decoration: none;
    &:hover {
        color: inherit;
    }
}

.spacer{
    flex-grow:1;
}


/* HIDE ARROW IN INPUT TYPE "number" (Chrome, Safari, Edge, Opera) */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* HIDE ARROW IN INPUT TYPE "number" (Firefox) */
input[type=number] {
  -moz-appearance: textfield;
}

/** remove yellow background on iOS (when using autofill) **/
input:-webkit-autofill {
    -webkit-box-shadow: inset 0 0 0px 9999px $white;
    opacity: 0;
}