@import '../../styles/shared';


$riskColor--1: #B0E551;
$riskColor--2: #D3D141;
$riskColor--3: #F1BD33;
$riskColor--4: #E5752B;
$riskColor--5: #D92C23;

.risk-slider {
    // margin-bottom: $default--margin;
    // padding-top: $default--padding;
    padding-bottom: $default--padding;
    position: relative;
    z-index: 5;

    &__header {
        margin: $default--margin/3 0 $default--margin/1.5 0;
        // display: block;
        // text-align: center;
        // padding: 0 $default--padding;
        @include displayFlex;
        justify-content: space-between;
        align-items: center;
    }

    &__body {
        padding: $default--padding/2;
        // border: 1px dotted blue;
        // padding: $default--padding/2 $default--padding*2;

        .rc-slider {
            margin-bottom: $default--margin/2;

            .rc-slider-mark {
                top: 24px;
                .rc-slider-mark-text:first-child {
                    transform: translateX(-18%) !important;
                }
                .rc-slider-mark-text:last-child {
                    transform: translateX(-76%) !important;
                }
            }
            .rc-slider-step {
                .rc-slider-dot {
                    // &-active:nth-child(1) {
                    //     background-color: $riskColor--1 !important;
                    // }
                    // &-active:nth-child(2) {
                    //     background-color: $riskColor--3 !important;
                    // }
                    // // &-active:nth-child(3) {
                    // //     background-color: $riskColor--2 !important;
                    // // }
                    // &-active:nth-child(4) {
                    //     background-color: $riskColor--2 !important;
                    // }
                    // &-active:nth-child(5) {
                    //     background-color: $riskColor--4 !important;
                    // }
                    &-active:nth-child(1) {
                        background-color: $riskColor--1 !important;
                    }
                    // &-active:nth-child(2) {
                    //     background-color: $riskColor--3 !important;
                    // }
                    &-active:nth-child(3) {
                        background-color: $riskColor--2 !important;
                    }
                    &-active:nth-child(4) {
                        background-color: $riskColor--3 !important;
                    }
                    &-active:nth-child(5) {
                        background-color: $riskColor--4 !important;
                    }
                }
            }
            .rc-slider-handle {
                $size: 22px;
                text-align: center;
                &:after {
                    @include displayFlex;
                    align-items: center;
                    justify-content: center;
                    width: $size;
                    height: $size;
                    color: $white;
                    border: 1px dotted transparent;
                    font-size: ($default--small)-1;
                }

                &[aria-valuenow="1"] {
                    background-color: $riskColor--1 !important;
                    &:after {
                        content: '1';
                    }
                }
                &[aria-valuenow="2"] {
                    background-color: $riskColor--2 !important;
                    &:after {
                        content: '2';
                    }
                }
                &[aria-valuenow="3"] {
                    background-color: $riskColor--3 !important;
                    &:after {
                        content: '3';
                    }
                }
                &[aria-valuenow="4"] {
                    background-color: $riskColor--4 !important;
                    &:after {
                        content: '4';
                    }
                }
                &[aria-valuenow="5"] {
                    background-color: $riskColor--5 !important;
                    &:after {
                        content: '5';
                    }
                }
            }
        }
        
    }

    &__results {
        margin-top: $default--margin;
        // margin-right: -$default--margin;
        // margin-left: -$default--margin;
        background: $grey3;
        padding: 0 $default--padding;
        min-height: $buttonBaseSize;
        @include displayFlex;
        align-items: center;
        justify-content: center;
        font-size: $default--small;
        color: $color-text--invert;
    }
}

.carbon-tooltips {
    color: $color-text;
    h6 {
        margin: unset;
        color: $color-text;
        font-weight: $default--weight-bolder;
        + p {
            margin-bottom: $default--margin;
        }
    }
    p {
        font-weight: $default--weight-normal;
        font-size: $default--small;
        text-align: justify;
    }

    ol {
        padding: 0;
        margin: 0;
        // margin: 0 0 0 22px;
        li {
            font-size: $default--small + 1;
            padding: $default--padding/3;
            @include displayFlex;
            justify-content: space-between;
            b { font-weight: $default--weight-bolder; }
            + li {
                border-top: 1px solid $color-border;
            }
        }
    }
}