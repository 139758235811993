@import '../../styles/shared';
 // TODO put this in variables so we can reuse it so we don't have to code over and over again.
.slide-item-container{
    @include displayFlex;
     flex-direction: column;
     justify-content: center;
     align-items: center;

     @include desktop {
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
     }

    .slide-item {
        position: relative;
        width: 100%;
        height: 144px; // 155px;
        opacity: 1;
        z-index: 100;
        // transition: height 0.4s ease-out 0.5s,opacity 0s ease-out;
        margin-bottom: $default--margin/1.5;

        @include desktop {
            max-width: 48.6%;
            // height: 140px;
        }

        // &-hide{    
        //     height: 0px;
        //     opacity: 0;
        //     margin-bottom: unset;
        // }

        // + .slide-item {
        //     margin-bottom: $default--margin/1.5;
        // }
    }
    
}

.app {
    &--mobileBrowser {
        .slide-item-container .slide-item {
            height: 144px;
            // &-hide{    
            //     height: 0px;
            //     opacity: 0;
            //     margin-bottom: unset;
            // }
        }
    }
}

