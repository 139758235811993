@import "../../styles/shared";

$default-bg: $white;
$colored-bg: $imas-lightestBlue;

.with-bottom-button {
  // position: relative;
  min-height: 100%;
  @include displayFlex;
  flex-direction: column;
  // border: 1px dashed green;
  // padding: $default--padding;

  &--gradientBg {
    position: relative;
    &:after {
      background: linear-gradient($colored-bg, $default-bg);
      width: calc(100% + 40px);
      height: 100%;
      content: "";
      position: absolute;
      top: -20px;
      left: -20px;
      z-index: 1;

      @include desktop {
        width: calc(100% + 80px);
        top: -40px;
        left: -40px;
      }
    }

    .with-bottom-button {
      &__bottom {
        z-index: 10;
      }
    }
  }

  &__title {
    margin-top: unset;
    margin-bottom: 16px;
  }

  &__desc {
    margin-top: unset;
    margin-bottom: $default--margin;
    font-weight: $default--weight-bolder;
    color: $color-text--heading;
  }

  &__main {
    flex-grow: 1;
    position: relative;
    z-index: 2;
  }

  &__bottom {
    @include displayFlex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: $default--padding/2;
    padding-bottom: $default--padding/2;
    // position: relative;
    // z-index: 10;

    .nw-button {
      // min-width: 100%;
      margin-top: $default--margin/2;
      @include tablet {
        width: 100%;
        padding-left: $standardPadding + 10;
        padding-right: $standardPadding + 10;
      }
      @include desktop {
        width: auto;
      }
    }

    &--swapOrder {
      flex-direction: column-reverse;
    }

    @include desktop {
      flex-direction: row;
      justify-content: flex-end;

      // .nw-button {
      //   // width: auto;
      //   padding-left: $standardPadding;
      //   padding-right: $standardPadding;
      // }
    }

    &--skip {
      @include desktop {
        justify-content: space-between;
      }
    }
  }
}
