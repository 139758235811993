@import '../../styles/shared';
@import "~animatewithsass/animate.scss";

$bgColor: $darkest;

.swipe-summary {
    list-style: none;
    padding: unset;
    margin: unset;
    @include fadeIn();
    @include displayFlex;
    flex-direction: column;

    @include desktop {
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;
        li {
            min-width: 48.6%;
            margin-bottom: $standardMargin;
        }
    }

    li {
        height: 120px;
        position: relative;
        background-color: $bgColor;
        background-repeat: no-repeat;
        background-size: cover;
        color: $color-text--invert;
        padding: $default--padding;
        position: relative;
        @include border-radius($default--border-radius--mid);
        @include displayFlex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        overflow: hidden;

        &:after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            background: linear-gradient(transparent, $black);
            z-index: 1;
        }

        + li {
            margin-top: $default--margin/2;
            @include desktop {
                margin-top: unset;
            }
        }

        img {
            $iconSize: 24px;
            width: $iconSize;
            height: $iconSize;
            align-self: flex-end;
        }

        h3 {
            width: 100%;
            align-self: flex-end;
            z-index: 2;
        }
    }
}