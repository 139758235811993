@import '../../styles/shared';

$nav--minHeightMobile: 80px;
$nav--minHeight: 120px;
$nav--bg: $white;

$container--maxWidth: 1020px;

.auth-wrapper {
    background: $color-dirtyBody;
    min-height: 100%;
    display: block;

    // &__container {
    //     border: 1px dotted blue;
    // }

    &__header {
        background: $nav--bg;
        border-bottom: 1px solid $color-border;
        position: fixed;
        top: 0;
        z-index: 10;
        width: 100%;

        @include displayFlex;

        .nav-header {
            margin: auto;
            max-width: $container--maxWidth;
            min-height: $nav--minHeightMobile;
            padding: $default--padding/2;

            @include displayFlex;
            
            @include desktop {
                // padding: unset;
                min-width: $container--maxWidth;
                min-height: $nav--minHeight;
            }

            img {
                margin-right: 16px;
                max-width: 48px;
                @include desktop {
                    max-width: 100%;
                }
            }

            &__content {
                @include displayFlex;
                flex-direction: column;
                justify-content: center;
                h3, h6 {
                    display: block;
                    margin: unset;
                    padding: 0;
                }
                h3 {
                    font-size: 18px;
                    @include desktop {
                        font-size: $default--h3;
                    }
                }
                h6 {
                    font-weight: $default--weight-normal;
                    font-size: $default--smaller;
                    @include desktop {
                        font-size: $default--h6;
                    }
                }
            }
        }
    }

    &__body {
        padding: $nav--minHeightMobile 0 $default--padding 0;

        @include tablet {
            padding-top: $nav--minHeightMobile*1.5;
        }

        @include desktop {
            padding-top: $nav--minHeightMobile*1.8;
        }

        &__container {
            max-width: $container--maxWidth;
            margin: auto;
        }
    }
}