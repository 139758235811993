@import '../../styles/shared';

$width-container: 400px;

%style {
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;

    @include tablet {
        position: relative;
    }
}

%videoStyle {
    /* Make video to at least 100% wide and tall */
    min-width: 100%; 
    min-height: 100%; 

    /* Setting width & height to auto prevents the browser from stretching or squishing the video */
    width: auto;
    height: auto;

    /* Center the video */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}

.video-container-desktop {
    position: fixed;
    top: 0;
    left: 0;
    min-width: 100vw;
    min-height: 100vh;

    &:after {
        position: absolute;
        content: '';
        min-width: 100vw;
        min-height: 100vh;
        background: rgba(0, 0, 0, 0.5);
    }
    
    video {
        @extend %videoStyle;
    }
}

.video-container {
    @extend %style;
    overflow: hidden;
    video {
        @extend %videoStyle;
    }
}

.intro {
    @extend %style;
    // border: 2px dotted red;
    overflow-x: hidden;
    z-index: 1;
    background: rgba(0, 0, 0, 0.5);
    color: $white;
    text-align: left;
    @include displayFlex;
    flex-direction: column;

    max-height: 100vh;
    overflow: auto;

    @include tablet {
        position: absolute;
    }

    @include desktop {
        background: unset;
        overflow: hidden;
    }

    &--noVideo {
        background: transparent;
    }

    &--noBg {
        background: transparent;
    }

    // &--ios {
        .intro__header {
            &__body {
                &__title {
                    max-width: 100%;
                    height: auto;

                    @include desktop {
                        max-width: 96%;
                    }
                }
                &__actions {
                    @include desktop {
                        @include displayFlex;
                        align-items: center;

                        button {
                            min-width: 160px;
                            padding-right: $standardPadding;
                            padding-left: $standardPadding;
                            margin-top: -10px;
                            margin-right: $standardMargin+10;
                        }
                    }
                }
            }

            .language-switcher {
                margin-bottom: $default--margin*4;
            }
        }
    // }

    &__header {
        &__body {
            padding: $default--padding*2 $default--padding*2 0 $default--padding*2;

            h2 {
                text-transform: uppercase;
            }
            h5 {
                margin-top: $default--margin;
                font-weight: $default--weight-bold;
                line-height: 160%;
                &.knowMore {
                    a {
                        @include displayFlex;
                        align-items: center;
                        color: white;
                        img {
                            position: relative;
                            margin-top: -3px;
                            margin-right: $default--margin/2;
                        }
                    }
                }
            }
        }

        &__topActions {
            position: relative;
            display: block;
            min-height: 60px;

            @include desktop {
                min-height: 140px;
            }
        }
    }

    &__spacer {
        flex-grow: 1;
    }

    &__actions {
        padding: $default--padding $default--padding*2 $default--padding*2;

        .nw-button {
            &--outline {
                border: unset;
                color: $white;
                span, a {
                    margin-right: $default--margin/2;
                    color: $white;
                }
            }
        }
    }
}

.video-wrapper {
    @include displayFlex;
    align-items: center;
    justify-content: center;
}

.sponsor {
    @include displayFlex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;

    @include desktop {
        flex-direction: row;
        padding-left: 44px;
    }

    span:first-child {
        font-weight: normal;
        margin-bottom: 10px;
    }
    &__logo {
        // background: white;
        // padding: 10px 10px 5px 10px;
        // @include border-radius(3px);
        @include desktop {
            margin-left: 10px;
        }
    }
}