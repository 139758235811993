@import '../../styles/shared';

.transitionPage {
    // border: 1px dotted red;
    text-align: center;
    padding: $default--padding*3 $default--padding/3 $default--padding*2 $default--padding/3;

    h5, h4 {
        line-height: 168%;
        font-weight: $default--weight-bolder;
        width: 100%;

        @include desktop {
            width: 60%;
            margin-right: auto;
            margin-left: auto;
        }
    }

    &__tipsBtn {
        @include appearance(none);
        background-color: inherit;
        border: unset;
        font-size: $default--h6;
        font-weight: $default--weight-bolder;
        margin-top: 20px;
        // color: $color-tertiary;
        color: $color-primary;
        padding: 16px 10px 10px 10px;

        // @include displayFlex;
        // align-items: center;
        // justify-content: center;
        margin-right: auto;
        margin-left: auto;
        border-top: 1px solid $color-border;

        @include desktop {
            font-size: $default--h5;
        }

        &:hover {
            color: lighten($color-primary, 10%);
            cursor: pointer;
        }

        img {
            margin-left: 5px;
            position: relative;
            top: 4px;
        }
    }
}

.knowMore {
    font-size: $default--small;
    @include desktop {
        font-size: $default--h5;
    }
}