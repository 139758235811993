// @use 'sass:map';
@import '../../styles/shared';

$layout--padding: $default--padding;
$layout--height: 100vh !default;

.layout {
    @include displayFlex;
    flex-direction: column;
    // background-color: map.get($light-colors, 'primary');
    
    &__body--withInfo {
        // border: 1px dotted orange;
        // padding: $layout--padding;
        @include calc(height, '#{$layout--height} - #{$default--navBarSize}');
        @include calc(max-height, '#{$layout--height} - #{$default--navBarSize}');
        @include displayFlex;
        padding: 0;
        overflow-y: auto;
        flex-direction: column;

        @include desktop {
            $layout--height: 100%;
            @include calc(height, '#{$layout--height} - #{$default--navBarSize}');
            @include calc(max-height, '#{$layout--height} - #{$default--navBarSize}');
        }

        &__content{
            @include box-sizing(content-box);
            // padding: 0 $layout--padding $layout--padding $layout--padding;
            padding: $layout--padding;
            
        }
        &__main {
            flex-grow: 1;
        }
        // &__main {
        //     // position: absolute;
        //     // bottom: 0;

        //     // @include desktop {
        //     //     position: relative;
        //     // }
        // }
    }
}