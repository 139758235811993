@import '../../styles/shared';

$max-small-icon-size: 12px;
$max-icon-size: 18px;
$max-chip-width--secondary: 38px;

$tags--primaryColor: $grey7;

.news-item {
    text-decoration: none;
    border: 1px solid $color-border;
    @include displayFlex;
    flex-direction: row;
    padding: $default--padding/2;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    color: $color-text;
    background-color: $color-body;
    cursor: pointer;
    outline: none;
    &:hover, &:active, &:focus {
        background-color: $color-primary--light;
    }
    img {
        height: $max-small-icon-size;
    }

    &__container {
        @include displayFlex;
        flex-direction: column;
        width: 93%;

        &__title {
            font-size: $default--small;
            margin: 0 0 $default--padding/2 0;
            text-align: start;
        }

        &__footer {
            @include displayFlex;
            justify-content: space-between;

            &__logoDateTime {
                @include displayFlex;
                align-items: center;
                font-size: $default--smaller;
                color: $color-text--smallText;
                img {
                    width: $max-icon-size;
                    height: $max-icon-size;
                    margin-right: $default--padding/10;
                }
            }

            &__tags {
                @include displayFlex;
                flex-direction: row;
                ul {
                    li {
                        margin: 0 0 0 $default--padding/2;
                        @include border-radius($default--border-radius--small/4);
                        min-width: $max-chip-width--secondary;
                        font-size: $default--smaller;
                        padding: $default--padding/7;
                        color: $color-text--tag;
                    }
                }
                .esg-chips__list--primary li {
                    background: $tags--primaryColor;
                }
            }
        }
    }
}