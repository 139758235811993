@import '../../styles/shared';

.homepage {
    background: $color-dirtyBody;
    @include border-radius($default--border-radius $default--border-radius 0 0);
    // padding: $default--padding;

    &__results {
        padding: $default--padding;
        min-height: 500px;
        // overflow: auto;
        // padding: 0 $default--padding 0 $default--padding;
        // @include displayFlex;
        // flex-direction: column;

        // &__container {
        //     padding: $default--padding;
        // }

        &__loader {
            @include displayFlex;
            justify-content: center;
            align-items: center;
            min-height: 180px;
        }
    }
}