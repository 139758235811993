@import '../../../styles/shared/shared';
$chips--spacing: 8px;
.filter{
  
  &__title{
    font-size: $default--small;
    padding-top: 4px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: $default--weight-bold;
    // opacity: 0.7;
  }

  &__items{
    @include displayFlex;
    flex-wrap: wrap;
    padding-bottom: 10px;

    .nw-button{
      color: $grey3;
      background-color: $white;
      font-size: 23px;
      padding: 10px;
      // margin-left: 8px;  
      @include border-radius($default--border-radius--small);
    }
    .nw-tag{
      margin-bottom: 8px;
      margin-right: $chips--spacing;
      font-size: $default--smaller;
      // + .nw-tag{
      //   margin-right: $chips--spacing;
      // }
    }
  }
  
  &--esgPref {
    h6 {
      font-size: $default--h6;
      color: $color-text;
      // font-weight: $default--weight-bold;
    }

    .nw-tag {
      font-size: $default--small;
      background: $imas-lighterOrange;
    }

    .nw-button { 
      background: $imas-lighterOrange;
      font-size: $default--small;
      color: $color-text;
      font-weight: $default--weight-normal;
      @include displayFlex;
      align-items: center;
      span {
        margin-right: 10px;
      }
    }
  }
}