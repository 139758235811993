@import '../../styles/shared';

.score {
    @include displayFlex;
    justify-content: space-between;

    @include desktop {
        margin-bottom: $standardMargin;
    }

    &__chart {
        margin-right: $default--margin/1.5;
        @include desktop {
            margin-right: $standardMargin*1.6;
        }
    }

    &__detail {
        h4, h5 {
            margin: unset;
            font-weight: $default--weight-bolder;
        }

        @include desktop {
            @include displayFlex;
            flex-direction: column;
            justify-content: center;
        }
    }
}

.sponsorv3 {
    @include displayFlex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: -8px 0 20px 0;
    padding-bottom: 10px;
    border-bottom: 1px dashed $color-border;

    @include desktop {
        // display: none !important;
        color: white;
        position: fixed;
        right: 32px;
        bottom: 32px;
        padding-bottom: unset;
        border: unset;
        margin: unset;
        @include displayFlex;
        flex-direction: column;
        span:first-child {
            font-weight: normal;
            margin-bottom: 6px;
        }
    }

    &__logo {
        // background: white;
        padding: 10px 10px 5px 10px;
        margin-left: 10px;
        @include border-radius(3px);

        @include desktop {
            padding: unset;
            margin-left: 0px;
        }
    }
}