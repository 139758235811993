@import '../../styles/shared';

$navbar--bg: $color-primary--light;
$navbar--defaultHeight: $default--navBarSize;
$navbar--minWidthButton: 32px; // @Todo define a variable once variable page is clean

.navbar {
    // border: 1px dotted red;
    min-height: $navbar--defaultHeight;
    @include displayFlex;
    align-items: center;
    justify-content: space-between;
    background: $navbar--bg;
    padding: 0 $default--padding/2;

    h3 {
        margin: 0 0 0 10px;
        font-size: $default--h5;
    }

    button {
        min-width: $navbar--minWidthButton;
        @include appearance(none);
        border: unset;
        background: inherit;
        &:focus {
            outline: none;
        }
    }

    &__spacer {
        flex-grow: 1;
    }

    span {
        min-width: $navbar--minWidthButton;
        min-height: $navbar--minWidthButton;
    }

    // &--gauge {
    //     background: transparent;
    //     padding: unset;
    // }
}