@import "../../styles/shared/shared";

$button-default-radius: $baseRadius * 2 !default;
$button-default-bg: $color-primary !default;
$button-default-color: $white !default;
$button-height: 32px !default;
$color-button-outline: $color-secondary;

.nw-button {
  background-color: $button-default-bg;
  color: $button-default-color;
  @include border-radius($button-default-radius);
  border: none;
  padding: $basedPadding/2 $standardPadding;
  height: $button-height;
  font-weight: $default--weight-bold;
  line-height: 0;

  &--primary {
    background-color: $color-primary;
    color: $white;
    &:hover {
      background-color: lighten($color-primary, 6%);
    }
    &:focus,
    &:active {
      background-color: darken($color-primary, 6%);
    }
    &.nw-button--outline {
      &:hover,
      &:focus,
      &:active {
        background-color: transparent;
        border-color: transparent;
      }
    }
    &:disabled {
      &:hover {
        background: $color-primary;
      }
    }
  }

  &--secondary {
    background-color: $color-secondary;
    &:hover {
      background-color: lighten($color-secondary, 6%);
    }
    &:focus,
    &:active {
      background-color: darken($color-secondary, 6%);
    }
  }

  &--tertiary {
    // background-image: linear-gradient($white-smoke, $whisper);
    &.nw-button--outline {
      color: $color-tertiary;
      border: unset;
    }
  }

  &--isWhite {
    background-color: $white;
    color: $grey3;
    &:hover {
      background-color: darken($grey1, 10);
      border-color: transparent;
    }
    &:focus,
    &:active {
      background-color: darken($grey1, 8);
    }
  }

  &--outline {
    background-color: transparent;
    color: $color-button-outline;
    border: 1px solid $color-button-outline;
  }

  &--big {
    // padding: $basedPadding/2 $basedPadding * 2.5;
    padding: $basedPadding/2;
    height: $buttonBaseSize;
    font-size: $default--h5;
  }

  &--isText {
    background: transparent;
    border: unset;
    color: $color-text;
    font-weight: $default--weight-bold;
    &:hover,
    &:focus,
    &:active {
      background-color: transparent;
      border-color: transparent;
    }
  }

  &--fullwidth {
    width: 100%;
  }

  &--disabled {
    opacity: 0.5;
  }

  cursor: pointer;

  &:focus,
  &:active {
    outline: none;
  }

  + .nw-button {
    margin-top: $default--margin/2;
  }
}
