@import "../../styles/shared";

.news-details {
  &__header {
    @include displayFlex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .article {
    white-space: pre-line;
  }
}
