@import '../../styles/shared';
@import "~animatewithsass/animate.scss";

.app {
  &--mobileBrowser {
    .card-container {
      height: 450px;
    }
  }
}

.card-container{
  position: relative;
  height: 504px;
  border-radius: 20px; 
  background-image: url("../../assets/images/africanKids.png");
  touch-action: none;

  @include desktop {
    height: 528px; // 440px;
  }

  .card-guide{
    position: absolute;
    z-index: 10;
    // left: 28%;
    left: 10%;
    top: 60%;
    width: 80%;
    @include displayFlex;
    justify-content: space-between;

    animation-name: fadding;
    animation-duration: 3s;
    animation-delay: 1s;
    animation-fill-mode: forwards;

    // @include desktop {
    //   width: 50%;
    // }

    .card-right-check{
      position: absolute;
      // left: 65%;
      right: 0;
      top: 19%;
    }
    .card-left-check{
      position: absolute;
      left: 0%;
      top: 19%;
    }

    
   }
  .check-img{
    // position: absolute;
    // z-index: 1000;
    // left: calc(50% - 32px);
    // top: calc(50% - 32px);

    position: absolute;
    z-index: 1000;
    left: calc(50% - 48px);
    top: calc(50% - 48px);
    width: 96px;
    height: 96px;
  }

  // .test-img{
  //   position: absolute;
  //   z-index: 1000;
  //   left: calc(50% - 48px);
  //   top: calc(50% - 48px);
  //   width: 96px;
  //   height: 96px;
  // }
  
  .card-wrapper {
    position: absolute;
    opacity: 1;
    background: rgba($black, 0.9);
    visibility: initial;
    width: 100%;
    height: 100%;
    transition: visibility 1s, opacity 1s linear;
    border: 2px solid $grey;
    border-radius: 20px;

    @include desktop {
      @include border-radius($baseRadius+4);
    }
    // + .card-wrapper {
    //   background: rgba(black, 0.9);
    // }
    .card-img{
      background-color: $darkest;
      background-size: cover;
      background-position: bottom;
      border-radius: 16px;
      height: 100%;
      // @include desktop {
      //   background-size: 52%;
      //   background-repeat: no-repeat;
      //   // background-position: bottom;
      // }
    }
    .card-wrapper-img{
      position: absolute;
      z-index: 1;
      width: 96px;
      height: 96px;
      left: calc(50% - 48px);
      top: calc(50% - 48px);
    }
     &--disapear{
      visibility: hidden;
      opacity: 0;
     }
     .card-text {
      position: absolute;
      z-index: 1000;
      width: 100%;
      text-align: center;
      color:$white;
      display: flex;
      flex-direction: column;
      align-items: center;
      bottom: 10%;
      text-shadow: 0 2px 3px rgba(35,35,35,0.125);

       &__title{
         font-size: 40px;
         font-weight: 800;
       }

       &__content{
        font-size: 17px;
        color: transparent;
        // width: 286px;
        padding: 4px;
        position: relative;
        top: 10px;

        &.outline {
          border: 1px solid white;
          opacity: .7;
          @include border-radius(3px);
          @include bounceIn();
        }

        @include desktop {
          font-size: 20px;
          top: 9px;
          &:hover {
            opacity: 1;
            cursor: pointer;
          }
        }
       }
     }

     
  }
}

@keyframes fadding {
  from {opacity: 1; visibility: initial;}
  to {opacity: 0; visibility: hidden;}
}
