@import '../../styles/shared';

.esg-slider {
    &__header {
        @include displayFlex;
        align-items: center;
        justify-content: space-between;
        padding: $default--padding/2 0;

        h5, h6 {
            margin: unset;
        }
    }
    &__body {
        padding-top: 2px;
        padding-bottom: $default--padding/1.8;
    }
}