@import "../../styles/shared";

// Colors
$checkmark--checkedColor: $color-primary;

// Size
$checkmark--size: 12px;
$checkmark--wrapper-size: 20px;
$checkmark--label-spacing: 6px;
$checkmark--item-spacing: 8px;
$checkmark--inner-padding: 5px;
$checkmark--font-size: 14px;

// Transition duration
$checkmark--transition-duration: 250ms;

.nw-checkmark {
    width: $checkmark--wrapper-size;
    height: $checkmark--wrapper-size;
    border-radius: 50%;
    border: 2px solid $white;
    position: relative;

    // width: 22px;

    // & + .nw-checkmark {
    //     margin-top: $checkmark--item-spacing;
    // }

    &__label-container {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
    }

    &__container {
        @include displayFlex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }

    &__switcher {
        display: inline-block;
        vertical-align: middle;
        width: $checkmark--size;
        height: $checkmark--size;
        border-radius: 50%;
        overflow: hidden;
        position: relative;
        transition: all $checkmark--transition-duration ease-out;

        &::before {
            content: "";
            display: block;
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-size: 63.63636364% auto;
        }
    }

    // &__label {
    //     margin-left: $checkmark--label-spacing;
    //     vertical-align: middle;
    //     font-size: $checkmark--font-size;
    //     color: blue;
    //     font-weight: $boldWeight;
    // }

    &__input {
        display: none;

        &:checked {
            & + .nw-checkmark__container {
                .nw-checkmark__switcher {
                    background-color: $checkmark--checkedColor;
                }
            }
        }
    }

    &--disabled {
        .nw-checkmark__container {
            opacity: 0.5;
            cursor: no-drop;
        }
    }
    &--checked {
        border-color: $checkmark--checkedColor;
    }

    // &--has-label {

    //     .nw-checkmark__container {
    //         padding-left: $checkmark--size + $checkmark--label-spacing;
    //     }

    //     .nw-checkmark__switcher {
    //         margin-left: -($checkmark--size + $checkmark--label-spacing);
    //     }
    // }

    // &--has-container {
    //     display: inline-block;
    //     margin-top: 0 !important;
    //     margin-bottom: $checkmark--item-spacing;
    //     color: black;

    //     &:not(:last-child) {
    //         margin-right: $checkmark--item-spacing;
    //     }

    //     .nw-checkmark {
    //         &__container {
    //             @include displayFlex;
    //             align-items: center;
    //             padding: $basedPadding/2;
    //             background-color: $checkmark--defaultBgContainer;
    //             border-radius: ($checkmark--size + $checkmark--inner-padding)*3;
    //             border: 1px solid blue;
    //         }

    //         &__switcher {
    //             background-color: $checkmark--defaultSwitchContainer;
    //             margin-left: 0 !important;
    //         }

    //         &__label {
    //             padding-right: $checkmark--inner-padding;
    //             color: $checkmark--defaultColorContainer;
    //             font-weight: $normalWeight;
    //             // flex: 1;
    //             // overflow: hidden;
    //             // text-overflow: ellipsis;
    //             // white-space: nowrap;
    //         }

    //         &__input {
    //             &:checked {
    //                 & + .nw-checkmark__container {
    //                     background-color: $checkmark--checkedBgContainer;
    //                     .nw-checkmark__switcher {
    //                         background-color: $checkmark--checkedColorContainer;

    //                     }

    //                     .nw-checkmark__label {
    //                         color: $checkmark--checkedColorContainer;
    //                     }
    //                 }
    //             }
    //         }
    //     }
    // }
}
