@import '../../../styles/shared';

.login-page {
    @include displayFlex;
    flex-direction: column;

    h1 {
        margin-top: unset;
        @include desktop {
            margin-top: $default--margin;
        }
    }

    @include tablet {
        flex-direction: row;
        align-items: center;
        padding-right: $default--padding;
        padding-left: $default--padding/4;
    }

    @include desktop {
        padding: unset;
        h1 {
            font-size: 58px;
            line-height: 120%;
        }
        p {
            font-size: $default--h4;
        }
    }


    &__row {
        padding: $default--padding;

        @include desktop {
            width: 50%;
        }

        .nw-button {
            @include displayFlex;
            justify-content: center;
            align-items: center;
        }

        &--withBg {
            background-size: 142%;
            background-position: center;
            background-repeat: no-repeat;
            text-align: center;
            flex-grow: 1;
            padding: unset;
            
            @include desktop {
                background-size: 132%;
            }

            img {
                margin: auto;
                position: relative;
                left: 8px;
                @include tablet {
                    max-width: 300px;
                }
                @include desktop {
                    left: 16px;
                    max-width: 350px;
                }
            }
        }
    }
}